<template>
  <v-dialog v-model="dialog" width="500" :persistent="loading">
    <template v-slot:activator="{ on }">
      <v-btn fixed dark fab bottom left color="#d386d3" large v-on="on">
        <v-icon x-large>$vuetify.icons.feedback</v-icon>
      </v-btn>
    </template>

    <v-card>
      <base-alert v-if="error">{{ error.message }}</base-alert>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-card-title>Feedback an wow-wow</v-card-title>
          <v-card-subtitle v-if="!success">Jede Rückmeldung macht uns besser!</v-card-subtitle>
          <v-card-text v-else>Jedes Feedback macht uns besser. Danke für deine ehrliche Meinung!</v-card-text>
          <v-card-text v-if="!success">
            <ValidationProvider name="E-Mail" rules="required|email" v-slot="{ errors }">
              <v-text-field label="E-Mail" type="email" v-model="email" :error-messages="errors" />
            </ValidationProvider>
            <ValidationProvider name="Nachricht" rules="required" v-slot="{ errors }">
              <v-textarea label="Nachricht" v-model="message" :error-messages="errors" />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false" v-if="success">Schliessen</v-btn>
            <v-btn text @click="dialog = false" :disabled="loading" v-if="!success">Abbrechen</v-btn>
            <v-btn type="submit" color="primary" :disabled="loading" :loading="loading" v-if="!success">Senden</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import platform from 'platform';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    dialog: false,
    email: '',
    message: '',
    success: false,
    loading: false,
    error: null,
  }),

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (oldValue && !value) {
          this.email = '';
          this.message = '';
          this.loading = false;
          this.error = null;
          this.success = false;
          this.$refs.form.reset();
        }
      },
    },
  },

  methods: {
    onSubmit() {
      this.loading = true;
      const messageWithDeviceInfo = `${this.message}\n\n${platform.os} / ${platform.name} ${platform.version}`;

      axios({
        method: 'post',
        data: { email: this.email, message: messageWithDeviceInfo, subject: 'wow-wow-cms Feedback' },
        url: process.env.VUE_APP_FEEDBACK_URL,
      })
        .then(() => {
          this.loading = false;
          this.success = true;
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
    },
  },
};
</script>
