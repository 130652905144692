<template>
  <v-snackbar v-model="snackbar.visible" right top :timeout="snackbar.timeout" :multi-line="snackbar.multiline === true" :color="snackbar.color">
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="close">Schliessen</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar() {
      return this.$store.getters['snackbar/get'];
    },
  },

  methods: {
    close() {
      this.$store.commit('snackbar/close');
    },
  },
};
</script>
