<template>
  <v-app-bar app flat clipped-left fixed :extension-height="6">
    <v-app-bar-nav-icon :class="!auth ? 'd-none d-sm-block v-app-bar__nav-icon-invisible' : null" @click="toggleNavigation" />
    <Logo />
    <v-spacer />
    <v-btn outlined class="mr-4" @click="donorbox = true" v-if="!$vuetify.breakpoint.xs">Spenden</v-btn>
    <div class="font-weight-thin text-caption text-sm-body-1" v-if="auth">{{ auth.email }}</div>
    <v-btn outlined href="https://wow-wow.ch/" v-else>Zur Startseite</v-btn>
    <template slot="extension">
      <canvas ref="animation" class="app-animation" />
    </template>
    <v-dialog v-model="donorbox" width="500">
      <v-card>
        <v-card-title>Wie kann ich wow-wow unterstützen?</v-card-title>
        <v-card-text>
          <p>
            Deine Spende fördert aktiv die Idee des Vereins «wow-wow», das aktuelle Kinder-Kultur- und Freizeitangebot von 0-12+ Jahren für alle zugänglich zu machen. Maximale kulturelle Teilhabe für
            Kinder.
            <br />Vielen Dank für Deine Unterstützung!
          </p>
          <p class="address">
            Verein wow-wow Spiegelgasse 5, 8001 Zürich
            <br />Spendenkonto: Zürcher Kantonalbank<br />CH73 0070 0114 8028 1519 9
          </p>
          <v-btn color="primary" href="https://donorbox.org/wow-wow" target="_blank">Hier kann man direkt spenden</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import Granim from 'granim';

export default {
  components: {
    Logo: () => import(/* webpackChunkName: "core" */ '@/components/core/Logo.vue'),
  },

  data: () => ({
    GraminObject: Object,
    donorbox: false,
  }),

  computed: {
    ...mapGetters({
      auth: 'auth/get',
    }),
  },

  methods: {
    toggleNavigation() {
      this.$store.dispatch('navigation/toggle');
      this.$store.dispatch('navigation/open');
    },
  },

  mounted() {
    this.GraminObject = new Granim({
      element: this.$refs.animation,
      direction: 'diagonal',
      isPausedWhenNotInView: true,
      states: {
        'default-state': {
          gradients: [
            ['#ff9966', '#ff5e62'],
            ['#00F260', '#0575E6'],
            ['#e1eec3', '#f05053'],
          ],
        },
      },

    });
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/components/VToolbar/variables.scss";

.app-animation {
  width: calc(100% + #{$toolbar-content-padding-x * 2});
  height: 6px;
  margin-left: -$toolbar-content-padding-x;
  margin-right: -$toolbar-content-padding-x;
}

.v-app-bar__nav-icon-invisible {
  visibility: hidden;
}
</style>
