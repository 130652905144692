var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":_vm.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fixed":"","dark":"","fab":"","bottom":"","left":"","color":"#d386d3","large":""}},on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("$vuetify.icons.feedback")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('base-alert',[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card-title',[_vm._v("Feedback an wow-wow")]),(!_vm.success)?_c('v-card-subtitle',[_vm._v("Jede Rückmeldung macht uns besser!")]):_c('v-card-text',[_vm._v("Jedes Feedback macht uns besser. Danke für deine ehrliche Meinung!")]),(!_vm.success)?_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"E-Mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail","type":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nachricht","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Nachricht","error-messages":errors},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(_vm.success)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Schliessen")]):_vm._e(),(!_vm.success)?_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Abbrechen")]):_vm._e(),(!_vm.success)?_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v("Senden")]):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }