<template>
  <v-navigation-drawer app clipped :mini-variant="!$vuetify.breakpoint.mdAndDown ? mini : false" :value="$vuetify.breakpoint.mdAndDown ? open : true" @input="onUpdate" v-if="auth">
    <v-list shaped>
      <template v-if="organization && organization.id">
        <v-list-item link :to="$url('/')" exact>
          <v-list-item-icon>
            <v-icon>mdi-calendar-month</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Veranstaltungen</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="$url('/settings')" exact>
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Konto verwalten</v-list-item-title>
        </v-list-item>
        <v-list-item link exact :to="`/organization/${$route.params.organizationID}`" v-if="showBackToOrganization && $route.params.organizationID">
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Zurück Hauptorganisation</v-list-item-title>
        </v-list-item>
      </template>
      <template v-if="$can('access', 'Admin')">
        <v-divider class="my-5" v-if="organization && organization.id" />
        <v-list-item link to="/" exact>
          <v-list-item-icon>
            <v-icon>mdi-factory</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Organisationen</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/activities" exact>
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Angebote</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/requests" exact>
        <v-list-item-icon>
          <v-icon>mdi-contacts</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Kontakte</v-list-item-title>
        </v-list-item>-->
        <v-list-item link to="/category" exact>
          <v-list-item-icon>
            <v-icon>mdi-tag-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Kategorien</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/blogs" exact>
          <v-list-item-icon>
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Blogs</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/users" exact>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Benutzer</v-list-item-title>
        </v-list-item>
      </template>
      <v-divider class="my-5" />
      <v-list-item link @click="signOut">
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Abmelden</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    openData: true,
  }),

  computed: {
    ...mapGetters({
      auth: 'auth/get',
      mini: 'navigation/mini',
      open: 'navigation/open',
      organization: 'organization/get',
    }),
    showBackToOrganization() {
      return !!this.$route.params.subOrganizationID;
    },
  },

  methods: {
    signOut() {
      this.$store.dispatch('auth/signOut');
    },
    onUpdate(value) {
      if (!value) {
        this.$store.commit('navigation/open', value);
      }
    },
  },
};
</script>
