<template>
  <v-app>
    <Navigation />
    <Appbar />
    <Content />
    <Snackbar />
    <Feedback />
  </v-app>
</template>

<script>
export default {
  name: 'Layout',

  components: {
    Appbar: () => import(/* webpackChunkName: "core" */ '@/components/core/Appbar.vue'),
    Content: () => import(/* webpackChunkName: "core" */ '@/components/core/Content.vue'),
    Navigation: () => import(/* webpackChunkName: "core" */ '@/components/core/Navigation.vue'),
    Snackbar: () => import(/* webpackChunkName: "core" */ '@/components/core/Snackbar.vue'),
    Feedback: () => import(/* webpackChunkName: "core" */ '@/components/core/Feedback.vue'),
  },
};
</script>

<style lang="scss">
.theme--light.v-data-table thead tr th {
  white-space: nowrap;
}
</style>
